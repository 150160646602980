<template>
  <div>
    <div class="card">
      <el-form :inline="true">
        <el-form-item>
          <el-select v-model="form.type">
            <el-option label="入库单号" :value="1"></el-option>
            <el-option label="货号" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input id="input"
            @keyup.enter.native="getList" v-model="form.num" clearable :placeholder="'请输入' + (form.type === 1? '入库单号': '货号')" style="width: 300px;"></el-input>
        </el-form-item>
         <el-form-item>
          <el-button type="primary" @click="getList" :loading="loading">添加</el-button>
        </el-form-item>
      </el-form>
      <div>
        <el-button type="primary" plain @click="handleClear">清空货品</el-button>
        <el-button type="primary" @click="dialogVisible = true">生成标签</el-button>
      </div>
    </div>
    <div class="total">
      <span class="txt">合计</span>
      共{{totalObj.total}}件
      <template v-for="item in totalObj.arr">
        <span :key="item.goods_type_id">，{{item.goods_type_name}}{{item.stock}}件</span>
      </template>
    </div>
    <div class="table" style="border-top: 1px solid #ddd;">
      <el-table
        :data="tableData.list[page - 1]"
        style="width: 100%"
        height="660"
        :row-class-name="handleIndex"
        stripe
      >
        <template v-for="item in rowList">
          <el-table-column
            :key="item.field_alias"
            :prop="item.field_alias"
            :label="item.field_text"
            align="center"
            :width="item.t_width">
            <template slot-scope="scope">
              <template v-if="item.field_alias === 'goods_name'">
                <div style="display: flex; align-items: center;">
                   {{scope.row[item.field_alias]}}
                  <template v-for="item in scope.row.goods_tag_pic">
                    <img :src="item" :key="item" style="margin-left: 5px; height: 15px;" />
                  </template>
                </div>
              </template>
              <template v-else>
                {{scope.row[item.field_alias] ? scope.row[item.field_alias] : '--'}}
              </template>
            </template>
          </el-table-column>
        </template>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="toDele(scope.row.index)">移除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagina">
      <el-pagination
        background
        :current-page="page"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="$store.state.pageSizes"
        :page-size="limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableList.length">
      </el-pagination>
    </div>
    <el-dialog
      title="生成标签"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose">
      <div class="table" style="border-top: 1px solid #ddd;">
        <el-table :data="totalObj.arr">
          <el-table-column label="品类" align="center" prop="goods_type_name"></el-table-column>
          <el-table-column label="数量" align="center" prop="stock"></el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button type="text" @click="toDownLoad(scope.row)">下载</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- <div class="title">下载通用模板文件</div>
      <div class="list">
        <div class="item">
          素金(按件)模板文件
        </div>
        <div class="item">
          素金(按克)模板文件
        </div>
        <div class="item white">
          镶嵌类模板文件
        </div>
        <div class="item white">
          裸钻类模板文件
        </div>
        <div class="item">
          玉石类模板文件
        </div>
        <div class="item">
          其他类模板文件
        </div>
      </div> -->
    </el-dialog>
  </div>
</template>

<script>
import _ from 'lodash';
import { getList } from "@/api/goods/goodsStock/goodsDetailedlist/index";

export default {
  data() {
    return {
      sameCount: 0, // 相同数据条数
      tableList: [],
      list: [],
      dialogVisible: false,
      loading: false,
      form: {
        type: 1,
      },
      limit: this.$store.state.pageSizes[0],
      page: 1,
      rowList: [
        { field_text: '序号', field_alias: 'index' },
        { field_text: '货号', field_alias: 'goods_number' },
        { field_text: '品名', field_alias: 'goods_name' },
        { field_text: '品类', field_alias: 'goods_type_name' },
        { field_text: '材质', field_alias: '100' },
        { field_text: '款式', field_alias: '101' },
        { field_text: '数量', field_alias: 'stock' },
        { field_text: '总重(g)', field_alias: '104' },
        { field_text: '标签价(元)', field_alias: 'sale_price' },
      ],
      tableData: {
        list: [],
      },
      totalObj: {
        total: 0,
        arr: [],
      },
    };
  },
  activated() {
    this.$nextTick(() => {
      const dom = document.getElementById('input');
      dom.focus();
    });
  },
  watch: {
    tableList: {
      handler(val) {
        const tableData = { ...this.tableData };
        tableData.list = _.chunk(val, this.limit);
        this.tableData = tableData;
        this.handleTotal();
      },
      deep: true,
    },
    limit: {
      handler() {
        const tableData = { ...this.tableData };
        tableData.list = _.chunk(this.tableList, this.limit);
        this.tableData = tableData;
      },
    },
  },
  methods: {
    toDownLoad(row) {
      const { goods_type_id, goods_type_name } = row;
      const arr = this.tableList.filter((item) => item.goods_type_id === goods_type_id);
      const goods_numbers = [];
      arr.forEach((item) => {
        goods_numbers.push(item.goods_number);
      });
      getList({
        goods_type_id,
        type: 'createLabel',
        goods_numbers,
      })
        .then((res) => {
          const blob = new Blob([res], { type: "application/vnd.ms-excel" });
          const a = document.createElement("a");
          a.setAttribute("download", `${goods_type_name}标签列表.csv`);
          a.href = URL.createObjectURL(blob);
          a.click();
        });
    },
    handleClear() {
      this.$confirm('确定要清空所有货品吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.tableList = [];
        this.$message({
          type: 'success',
          message: '删除成功!',
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除',
        });
      });
    },
    // 计算总数
    handleTotal() {
      const totalObj = {
        total: 0,
        arr: [],
      };
      const obj = {};
      this.tableList.forEach((item) => {
        totalObj.total += item.stock;
        if (!obj[item.goods_type_id]) {
          obj[item.goods_type_id] = true;
          const good = {
            goods_type_id: item.goods_type_id,
            stock: item.stock,
            goods_type_name: item.goods_type_name,
          }
          totalObj.arr.push(good);
        } else {
          totalObj.arr.forEach((good) => {
            if (good.goods_type_id === item.goods_type_id) {
              good.stock += item.stock;
            }
          });
        }
      });
      this.totalObj = totalObj;
    },
    toDele(index) {
      this.tableList.splice(index - 1, 1);
    },
    indexMethod(index) {
      return index + 1;
    },
    toFocus() {
      const dom = document.getElementById('input');
      const form = { ...this.form };
      form.num = '';
      this.form = form;
      dom.focus();
    },
    getList() {
      // 按照入库单号搜索
      if (!this.form.num) {
        this.$message.error('请输入搜索内容');
        this.toFocus();
        return;
      }
      const params = {
        type: 'createLabel',
      };
      if (this.form.type === 1) {
        params.entry_number = this.form.num;
      }
      if (this.form.type === 2) {
        params.goods_number = this.form.num;
      }
      this.loading = true;
      getList({ ...params })
        .then((res) => {
          this.loading = false;
          if (res.code === 1) {
            this.sameCount = 0;
            if (res.data.list.length) {
              // 如果表格没数据
              if (!this.tableList.length) {
                this.tableList = res.data.list;
              } else {
                // 如果表格有数据
                res.data.list.forEach((item) => {
                  this.tableList.push(item);
                });
                // 去重
                const obj = {};
                const newArr = [];
                this.tableList.forEach((item) => {
                  if (!obj[item.goods_id]) {
                    obj[item.goods_id] = true;
                    newArr.push(item);
                  } else {
                    this.sameCount += 1;
                  }
                });
                this.tableList = [...newArr];
                if (this.sameCount) {
                  this.$message.error(`有${this.sameCount}条数据重复添加。`);
                }
              }
            } else {
              this.$message.error('查询不到该数据');
            }
          } else {
            this.$message.error(res.data.msg);
            this.toFocus();
          }
          this.toFocus();
        })
        .catch(() => {
          this.loading = false;
          this.toFocus();
        });
    },
    handleIndex({row, rowIndex}) {
      row.index = rowIndex + 1;
    },
    handleClose() {
      this.dialogVisible = false;
    },
    handleSizeChange(limit) {
      this.page = 1;
      this.limit = limit;
    },
    handleCurrentChange(page) {
      this.page = page;
    },
  },
};
</script>

<style lang="less" scoped>
.list {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #ddd;
  border-bottom: none;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    background: #ddd;
    width: 1px;
    height: 100%;
    left: 50%;
  }
  .item {
    line-height: 44px;
    text-align: center;
    color: #2791FF;
    text-decoration: underline;
    width: 50%;
    background: #F5F6FA;
    cursor: pointer;
    border-bottom: 1px solid #ddd;
  }
  .white {
    background: #fff;
  }
}
.title {
  line-height: 40px;
  padding-left: 10px;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    width: 2px;
    height: 9px;
    border-radius: 99px;
    background: #1678FF;
    top: 16px;
    left: 0;
  }
}
.card {
  display: flex;
  justify-content: space-between;
  /deep/ .el-form-item {
    margin-bottom: 0px;
  }
}
.total {
  background: white;
  padding: 20px;
  color: #565656;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  .txt {
    background: #2791FF;
    color: white;
    padding: 2px 8px;
    border-radius: 5px;
    margin-right: 10px;
  }
}
</style>
